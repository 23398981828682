import React, { useContext } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts';
import { ThemeContext } from '../context/ThemeContext';

const BitcoinChart = ({ data }) => {
  const { theme } = useContext(ThemeContext);

  // Define colors based on theme
  const axisTickColor = theme === 'light' ? '#666' : '#A0AEC0';
  const gridColor = theme === 'light' ? '#f0f0f0' : '#4A5568';
  const lineColor = theme === 'light' ? '#3182CE' : '#63B3ED';
  const tooltipBgColor = theme === 'light' ? '#ffffff' : '#2D3748';
  const tooltipBorderColor = theme === 'light' ? '#e2e8f0' : '#4A5568';
  const tooltipTextColor = theme === 'light' ? '#4A5568' : '#E2E8F0';
  const referenceLineColorPositive = theme === 'light' ? '#38A169' : '#68D391';
  const referenceLineColorNegative = theme === 'light' ? '#E53E3E' : '#FC8181';

  // Se não houver dados, não renderize o gráfico
  if (!data || data.length === 0) {
    return <div className="h-64 flex items-center justify-center text-gray-500 dark:text-gray-400">Nenhum dado disponível</div>;
  }

  // Formatar timestamp para o tooltip
  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  // Formatar valor para o eixo Y
  const formatYAxis = (value) => {
    return `$${value.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
  };

  // Configuração do tooltip personalizado
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ 
            backgroundColor: tooltipBgColor, 
            border: `1px solid ${tooltipBorderColor}`, 
            color: tooltipTextColor 
        }} className="p-3 shadow-md rounded-md text-sm">
          <p className="font-medium mb-1">{formatTimestamp(label)}</p>
          <p style={{ color: lineColor }} className="font-semibold">
            ${payload[0].value.toLocaleString('en-US', { maximumFractionDigits: 2 })}
          </p>
        </div>
      );
    }
    return null;
  };

  // Calcular os domínios para o eixo Y (com um pequeno padding)
  const prices = data.map(d => d.price);
  const minPrice = Math.min(...prices) * 0.998;
  const maxPrice = Math.max(...prices) * 1.002;
  
  // Calcular preço atual e inicial para a linha de referência
  const currentPrice = data[data.length - 1].price;
  const initialPrice = data[0].price;

  return (
    <div className="h-80">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke={gridColor} />
          <XAxis
            dataKey="timestamp"
            tickFormatter={formatTimestamp}
            tick={{ fill: axisTickColor, fontSize: 12 }}
            tickCount={6}
            minTickGap={30}
            stroke={axisTickColor}
          />
          <YAxis
            domain={[minPrice, maxPrice]}
            tickFormatter={formatYAxis}
            tick={{ fill: axisTickColor, fontSize: 12 }}
            tickCount={5}
            width={80}
            stroke={axisTickColor}
          />
          <Tooltip content={<CustomTooltip />} cursor={{ stroke: axisTickColor, strokeWidth: 1, strokeDasharray: '3 3' }} />
          <ReferenceLine
            y={initialPrice}
            stroke={currentPrice >= initialPrice ? referenceLineColorPositive : referenceLineColorNegative}
            strokeDasharray="3 3"
            strokeWidth={1}
          />
          <Line
            type="monotone"
            dataKey="price"
            stroke={lineColor}
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 6, fill: lineColor, stroke: tooltipBgColor, strokeWidth: 2 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BitcoinChart;