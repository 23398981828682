import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCopy, FaExternalLinkAlt, FaCoffee, FaBtc } from 'react-icons/fa';
import { ThemeContext } from '../context/ThemeContext';

const DonationBox = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const btcAddress = "1GWNueYk247NG42ETQ5USLXwLJdueZBeg2";
  const binanceReferralLink = "https://www.binance.com/activity/referral-entry/CPA?ref=CPA_009HB4T9DP";
  const kofiLink = "https://ko-fi.com/bitcoinnow";
  const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(btcAddress);
      setCopySuccess(t('donationAddressCopied'));
      setTimeout(() => setCopySuccess(''), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setCopySuccess('Copy failed');
      setTimeout(() => setCopySuccess(''), 2000);
    }
  };

  // Common styles for buttons/links within grid items
  const linkButtonStyle = "inline-flex items-center justify-center w-full px-3 py-2 text-sm font-semibold rounded-md transition-colors duration-150 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900";
  const binanceStyle = `${linkButtonStyle} bg-yellow-500 hover:bg-yellow-600 text-white focus:ring-yellow-400`;
  const kofiStyle = `${linkButtonStyle} bg-blue-500 hover:bg-blue-600 text-white focus:ring-blue-400`;
  const btcCopyStyle = `p-1.5 bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 text-gray-700 dark:text-gray-200 rounded-md transition-colors duration-150 text-xs flex items-center flex-shrink-0 focus:outline-none focus:ring-2 focus:ring-primary dark:focus:ring-primary-light focus:ring-offset-1 dark:focus:ring-offset-gray-800 ml-2`;

  // Style for the grid items (cards)
  const gridItemStyle = "bg-white dark:bg-gray-700 rounded-lg p-4 shadow-inner flex flex-col items-center justify-between text-center";

  return (
    <div className="mt-8 bg-gray-100 dark:bg-gray-800 rounded-xl shadow-md p-4 transition-colors duration-200">
      <h3 className="text-md font-semibold text-gray-700 dark:text-gray-200 mb-4 text-center">{t('donationTitle')}</h3>
      
      {/* Grid container for donation methods */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        
        {/* BTC Column */}
        <div className={gridItemStyle}>
          <div className="mb-3">
            <FaBtc className="text-orange-500 dark:text-yellow-400 text-3xl mx-auto mb-2" />
            <p className="text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">Bitcoin</p>
          </div>
          <div className="w-full">
            <code 
              className="block text-xs text-gray-500 dark:text-gray-400 break-all mb-2" 
              title={btcAddress}
            >
              {btcAddress}
            </code>
            <button
              onClick={copyToClipboard}
              title={copySuccess || t('donationCopyAddress')}
              className={`w-full ${btcCopyStyle} justify-center ${copySuccess ? 'bg-green-200 dark:bg-green-700 text-green-800 dark:text-green-100' : ''}`}
            >
              <FaCopy />
              <span className="ml-1">{copySuccess || t('donationCopyAddress')}</span>
            </button>
          </div>
        </div>

        {/* Binance Column */}
        <div className={gridItemStyle}>
          <div className="mb-3">
            <FaExternalLinkAlt className="text-yellow-500 text-3xl mx-auto mb-2" />
            <p className="text-sm font-medium text-gray-700 dark:text-gray-200">Binance Referral</p>
          </div>
          <a href={binanceReferralLink} target="_blank" rel="noopener noreferrer nofollow" className={binanceStyle} >
            <span>{t('donationReferralLinkText')}</span>
            <FaExternalLinkAlt className="ml-1 h-3 w-3 opacity-80" />
          </a>
        </div>

        {/* Ko-fi Column */}
        <div className={gridItemStyle}>
          <div className="mb-3">
            <FaCoffee className="text-blue-500 dark:text-blue-400 text-3xl mx-auto mb-2" />
            <p className="text-sm font-medium text-gray-700 dark:text-gray-200">Ko-fi</p>
          </div>
          <a href={kofiLink} target="_blank" rel="noopener noreferrer nofollow" className={kofiStyle} >
            <span>{t('donationKofiButtonText')}</span>
          </a>
        </div>

      </div>
    </div>
  );
};

export default DonationBox; 