import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../context/ThemeContext';
import { useNavigate, useLocation } from 'react-router-dom'; // Import hooks for navigation

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate(); // Hook for navigation
  const location = useLocation(); // Hook to get current location

  const changeLanguage = (lng) => {
    // Extract the current path without the language prefix
    const currentLangPrefix = `/${i18n.language.split('-')[0]}`;
    let newPath = '';
    if (location.pathname.startsWith(currentLangPrefix)) {
      newPath = location.pathname.substring(currentLangPrefix.length);
    }
    // Ensure the base path starts with / or is empty
    if (newPath && !newPath.startsWith('/')) {
        newPath = '/' + newPath;
    }
    if (!newPath) {
        newPath = '/'; // Default to root if no path exists after language
    }

    // Navigate to the new language URL, preserving search params and hash
    navigate(`/${lng}${newPath}${location.search}${location.hash}`);

    // i18n language will be changed by the useEffect in AppContent based on the URL param
    // i18n.changeLanguage(lng); // This line is no longer needed here
  };

  // Base style for buttons
  const baseButtonStyle = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '0.9rem', // Adjusted font size for text
    fontWeight: '600', // Make text bold
    margin: '0 4px',
    padding: '4px 8px',
    borderRadius: '4px', // Rounded corners
    color: theme === 'light' ? '#4A5568' : '#CBD5E0', // Text color based on theme
    opacity: 0.7,
    transition: 'opacity 0.2s ease-in-out, background-color 0.2s ease-in-out',
  };

  // Style for the currently selected language button
  const selectedButtonStyle = {
    ...baseButtonStyle,
    opacity: 1,
    backgroundColor: theme === 'light' ? 'rgba(66, 153, 225, 0.1)' : 'rgba(66, 153, 225, 0.2)', // Subtle background for selected
    color: theme === 'light' ? '#2C5282' : '#90CDF4', // Adjusted selected text color (primary dark/light blue)
  };

  // Determine current language base (e.g., 'en' from 'en-US')
  const currentLang = i18n.language.split('-')[0];

  return (
    <div style={{ marginTop: '10px' }}>
      {/* English Button */}
      <button 
        onClick={() => changeLanguage('en')} 
        style={currentLang === 'en' ? selectedButtonStyle : baseButtonStyle}
        title="English"
        onMouseEnter={e => { if (currentLang !== 'en') e.currentTarget.style.opacity = 1; }} 
        onMouseLeave={e => { if (currentLang !== 'en') e.currentTarget.style.opacity = 0.7; }} 
      >
        EN
      </button>
      
      {/* Portuguese Button */}
      <button 
        onClick={() => changeLanguage('pt')} 
        style={currentLang === 'pt' ? selectedButtonStyle : baseButtonStyle}
        title="Português"
        onMouseEnter={e => { if (currentLang !== 'pt') e.currentTarget.style.opacity = 1; }}
        onMouseLeave={e => { if (currentLang !== 'pt') e.currentTarget.style.opacity = 0.7; }}
      >
        PT
      </button>
      
      {/* Spanish Button */}
      <button 
        onClick={() => changeLanguage('es')} 
        style={currentLang === 'es' ? selectedButtonStyle : baseButtonStyle}
        title="Español"
        onMouseEnter={e => { if (currentLang !== 'es') e.currentTarget.style.opacity = 1; }}
        onMouseLeave={e => { if (currentLang !== 'es') e.currentTarget.style.opacity = 0.7; }}
      >
        ES
      </button>
    </div>
  );
};

export default LanguageSelector; 