import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaBitcoin } from 'react-icons/fa';
import LanguageSelector from './LanguageSelector';
import ThemeSwitcher from './ThemeSwitcher';

const Header = ({ currentPrice }) => {
  const { t } = useTranslation();

  return (
    <header className="flex flex-col md:flex-row justify-between items-center mb-6 md:mb-8">
      <div className="flex items-center mb-4 md:mb-0">
        <div className="bg-primary text-white p-2 rounded-lg mr-3 flex items-center justify-center">
          <FaBitcoin className="h-8 w-8" />
        </div>
        <div>
          <h1 className="text-3xl font-bold text-primary-dark dark:text-primary-light transition-colors duration-200">Bitcoin Now</h1>
          <p className="text-gray-600 dark:text-gray-400 transition-colors duration-200">{t('headerSubtitle')}</p>
        </div>
      </div>
      
      <div className="flex items-center space-x-2">
        <LanguageSelector />
        <ThemeSwitcher />
      </div>
    </header>
  );
};

export default Header;