import React, { useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { ThemeContext } from '../context/ThemeContext';

const FearGreedGauge = ({ value }) => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);

  const textColor = theme === 'light' ? '#4A5568' : '#CBD5E0';
  const svgTickLabelColor = theme === 'light' ? '#666' : '#A0AEC0';
  const svgPointerColor = theme === 'light' ? '#333' : '#E2E8F0';
  const categoryColor = theme === 'light' ? '#4A5568' : '#CBD5E0';

  if (value === null || value === undefined) {
    return (
      <div className="flex flex-col items-center justify-center h-64">
        <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-primary dark:border-primary-light"></div>
        <p className="mt-4 text-gray-600 dark:text-gray-400">{t('gaugeLoading')}</p>
      </div>
    );
  }

  let categoryKey;
  let valueColor;
  
  if (value <= 20) {
    categoryKey = 'fearGreedCategoryExtremeFear';
    valueColor = 'rgb(235, 83, 83)';
  } else if (value <= 40) {
    categoryKey = 'fearGreedCategoryFear';
    valueColor = 'rgb(250, 160, 90)';
  } else if (value <= 60) {
    categoryKey = 'fearGreedCategoryNeutral';
    valueColor = 'rgb(253, 212, 88)';
  } else if (value <= 80) {
    categoryKey = 'fearGreedCategoryGreed';
    valueColor = 'rgb(120, 210, 130)';
  } else {
    categoryKey = 'fearGreedCategoryExtremeGreed';
    valueColor = 'rgb(73, 190, 110)';
  }

  // Map value [0, 100] to angle [180, 360] degrees for rotation
  // Value 0 -> 180 deg (left), Value 50 -> 270 deg (up), Value 100 -> 360 deg (right)
  const targetAngle = 180 + (value * 1.8);

  // Calculate the actual rotation needed for the transform
  // The pointer initially points left (180 deg), so rotate by targetAngle - 180
  const transformRotation = targetAngle - 180; // This simplifies to value * 1.8

  const ticks = [];
  for (let i = 0; i <= 100; i += 20) {
    // Map tick value [0, 100] to angle [180, 360] degrees
    const tickAngle = 180 + (i * 1.8);
    const tickRadians = (tickAngle * Math.PI) / 180;
    const x1 = 100 + 70 * Math.cos(tickRadians);
    const y1 = 100 + 70 * Math.sin(tickRadians);
    const x2 = 100 + 80 * Math.cos(tickRadians);
    const y2 = 100 + 80 * Math.sin(tickRadians);
    
    ticks.push(
      <line
        key={i}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        stroke={svgTickLabelColor}
        strokeWidth="2"
      />
    );
  }

  const gradientStops = [
    { offset: "0%", color: "rgb(235, 83, 83)", label: "0" },
    { offset: "20%", color: "rgb(250, 160, 90)", label: "20" },
    { offset: "40%", color: "rgb(253, 212, 88)", label: "40" },
    { offset: "60%", color: "rgb(120, 210, 130)", label: "60" },
    { offset: "80%", color: "rgb(73, 190, 110)", label: "80" },
    { offset: "100%", color: "rgb(73, 190, 110)", label: "100" }
  ];
  
  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <svg width="210" height="120" viewBox="0 0 200 120">
          <defs>
            <linearGradient id="gaugeGradient" gradientUnits="userSpaceOnUse" x1="20" y1="100" x2="180" y2="100">
              {gradientStops.map((stop) => (
                <stop
                  key={stop.offset}
                  offset={stop.offset}
                  stopColor={stop.color}
                />
              ))}
            </linearGradient>
          </defs>
          
          <path
            d="M20,100 A80,80 0 0,1 180,100"
            fill="none"
            stroke={theme === 'light' ? '#EAEAEA' : '#4A5568'}
            strokeWidth="12"
            strokeLinecap="round"
          />
          
          <path
            d="M20,100 A80,80 0 0,1 180,100"
            fill="none"
            stroke="url(#gaugeGradient)"
            strokeWidth="12"
            strokeLinecap="round"
            strokeDasharray="251.2"
            strokeDashoffset={251.2 - (251.2 * value / 100)}
          />
          
          {ticks}
          
          {gradientStops.map((stop) => {
            const percentage = parseInt(stop.offset);
            // Map label percentage [0, 100] to angle [180, 360] degrees
            const labelAngle = 180 + (percentage * 1.8);
            const labelRadians = (labelAngle * Math.PI) / 180;
            
            const x = 100 + 95 * Math.cos(labelRadians);
            const y = 100 + 95 * Math.sin(labelRadians);
            
            return (
              <text
                key={stop.label}
                x={x}
                y={y}
                fontSize="10"
                textAnchor="middle"
                dominantBaseline="middle"
                fill={svgTickLabelColor}
              >
                {stop.label}
              </text>
            );
          })}
          
          {/* Rotate the group containing the pointer */}
          <g transform={`rotate(${transformRotation}, 100, 100)`}>
            {/* Pointer initially points left (180 degrees / value 0), rotation adjusts it based on the value */}
            <line
              x1="100"
              y1="100"
              x2="20" // Points left towards the start of the arc (value 0)
              y2="100"
              stroke={svgPointerColor}
              strokeWidth="2"
            />
            <circle cx="100" cy="100" r="5" fill={svgPointerColor} />
          </g>
        </svg>
      </div>
      
      <div className="text-center mt-6">
        <div className="text-5xl font-bold" style={{ color: valueColor }}>
          {value}
        </div>
        <div className="text-xl font-medium mt-1" style={{ color: categoryColor }}>
          {t(categoryKey)}
        </div>
        <div className="mt-4 text-sm max-w-xs" style={{ color: textColor }}>
          <p>{t('gaugeDescriptionP1')}</p>
          <p className="mt-2">
            <Trans
              i18nKey="gaugeDescriptionP2Scale"
              values={{
                extremeFear: t('fearGreedCategoryExtremeFear'),
                extremeGreed: t('fearGreedCategoryExtremeGreed')
              }}
              components={[<span className="font-medium" style={{ color: textColor }} />, <span className="font-medium" style={{ color: textColor }} />]}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default FearGreedGauge;