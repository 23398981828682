import React, { useContext } from 'react';
import { ThemeContext } from '../context/ThemeContext';
import { FaSun, FaMoon } from 'react-icons/fa'; // Import icons

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  const buttonStyle = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '1.5rem',
    color: theme === 'light' ? '#4A5568' : '#CBD5E0', // Adjust icon color based on theme (gray-700 / gray-400)
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };

  return (
    <button 
      onClick={toggleTheme}
      style={buttonStyle}
      title={theme === 'light' ? 'Switch to Dark Mode' : 'Switch to Light Mode'} // Add tooltip
    >
      {theme === 'light' ? <FaMoon /> : <FaSun />} {/* Show moon in light mode, sun in dark mode */}
    </button>
  );
};

export default ThemeSwitcher; 