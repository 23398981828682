import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../context/ThemeContext';

const BitcoinNews = () => {
  const { t } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRssNews = async () => {
      setLoading(true);
      setError(null);

      // RSS Feed URL (Cointelegraph Bitcoin Tag)
      const rssFeedUrl = 'https://cointelegraph.com/rss/tag/bitcoin';
      // rss2json API URL
      const apiUrl = `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(rssFeedUrl)}`;

      try {
        const response = await axios.get(apiUrl);
        
        if (response.data.status === 'ok') {
          // Extract items and limit to 6
          setArticles(response.data.items?.slice(0, 6) || []);
        } else {
          // Handle rss2json specific error
          console.error('Error fetching RSS feed via rss2json:', response.data.message);
          setError(t('newsError') + `: ${response.data.message || 'Failed to load RSS feed'}`);
        }
      } catch (err) {
        console.error('Error fetching RSS feed:', err);
        setError(t('newsError'));
      } finally {
        setLoading(false);
      }
    };

    fetchRssNews();
    
    // No need to refetch on language change as the feed is language-specific (usually English)
  }, [t]); // Keep t as dependency for error messages

  // Helper to format date
  const formatDate = (isoDate) => {
    if (!isoDate) return '';
    try {
      return new Date(isoDate).toLocaleDateString(undefined, { 
        year: 'numeric', month: 'short', day: 'numeric' 
      });
    } catch (e) {
      console.error('Error formatting date:', e);
      return isoDate; // fallback to original string
    }
  };

  return (
    <div className="mt-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 transition-colors duration-200">
      <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">{t('newsSimpleTitle')}</h2>
      {loading && (
        <div className="flex justify-center items-center h-40">
          <div className="animate-spin rounded-full h-10 w-10 border-t-4 border-b-4 border-primary dark:border-primary-light"></div>
          <p className="ml-4 text-gray-600 dark:text-gray-400">{t('newsLoading')}</p>
        </div>
      )}
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 dark:bg-red-900 dark:border-red-700 dark:text-red-200 px-4 py-3 rounded">
          {error}
        </div>
      )}
      {!loading && !error && articles.length === 0 && (
        <p className="text-gray-600 dark:text-gray-400">{t('newsNotFound')}</p>
      )}
      {!loading && !error && articles.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {articles.map((article, index) => (
            <div key={article.guid || index} className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden flex flex-col transition-colors duration-200">
              {article.thumbnail && (
                <img src={article.thumbnail} alt={article.title || 'News image'} className="w-full h-40 object-cover" onError={(e) => e.target.style.display='none'} />
              )}
              <div className="p-4 flex flex-col flex-grow">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2 hover:text-primary dark:hover:text-primary-light">
                  <a href={article.link} target="_blank" rel="noopener noreferrer">
                    {article.title}
                  </a>
                </h3>
                <p className="text-xs text-gray-500 dark:text-gray-400 mb-2 flex-grow">{formatDate(article.pubDate)}</p>
                <a 
                  href={article.link} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-sm text-primary dark:text-primary-light hover:text-primary-dark dark:hover:text-primary font-medium self-start mt-auto"
                >
                  {t('newsReadMore')} &rarr;
                </a>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BitcoinNews; 