import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, useParams, Navigate, useLocation } from 'react-router-dom';
import BitcoinChart from './components/BitcoinChart';
import FearGreedGauge from './components/FearGreedGauge';
import Header from './components/Header';
import BitcoinNews from './components/BitcoinNews';
import DonationBox from './components/DonationBox';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import LanguageSelector from './components/LanguageSelector';
import ThemeSwitcher from './components/ThemeSwitcher';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Component to handle language context and render main content
function AppContent() {
  const { lang } = useParams();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [bitcoinData, setBitcoinData] = useState([]);
  const [fearGreedIndex, setFearGreedIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPrice, setCurrentPrice] = useState(null);

  // List of supported languages (adjust as needed)
  const supportedLanguages = ['en', 'pt', 'es'];
  const defaultLanguage = 'en'; // Default language

  // Determine the language to use and if the URL language is valid
  const resolvedLang = lang && supportedLanguages.includes(lang) ? lang : defaultLanguage;
  const isLangValid = !lang || supportedLanguages.includes(lang);

  // Effect to change i18n language based on resolved language
  useEffect(() => {
    if (i18n.language !== resolvedLang) {
      i18n.changeLanguage(resolvedLang);
    }
  }, [resolvedLang, i18n]);

  // Effect to fetch data - THIS MUST BE CALLED UNCONDITIONALLY AT THE TOP LEVEL
  useEffect(() => {
    // Only fetch if the language is valid, otherwise, we are likely redirecting
    if (!isLangValid) return;

    const fetchBitcoinData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Usando a API CoinGecko
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/bitcoin/market_chart', {
            params: { vs_currency: 'usd', days: 1 }
          }
        );
        const formattedData = response.data.prices.map(item => ({ timestamp: new Date(item[0]), price: item[1] }));
        setBitcoinData(formattedData);
        if (formattedData.length > 0) setCurrentPrice(formattedData[formattedData.length - 1].price);

        // Buscar o índice de medo e ganância
        const fearGreedResponse = await axios.get('https://api.alternative.me/fng/');
        if (fearGreedResponse.data?.data?.[0]) setFearGreedIndex(parseInt(fearGreedResponse.data.data[0].value, 10));

        setLoading(false);
      } catch (err) {
        console.error('Erro ao buscar dados:', err);
        setError(t('errorMessage'));
        setLoading(false);
        // Dados de exemplo para demonstração
        const demoData = generateDemoData();
        setBitcoinData(demoData);
        setCurrentPrice(demoData[demoData.length - 1].price);
        setFearGreedIndex(65);
      }
    };

    fetchBitcoinData();
    const intervalId = setInterval(fetchBitcoinData, 5 * 60 * 1000);
    return () => clearInterval(intervalId);
  // Add isLangValid to dependency array to prevent fetching if redirecting
  }, [t, i18n.language, isLangValid]);

  // This function is now only called if data fetching fails
  const generateDemoData = () => {
    const data = []; const now = new Date(); const basePrice = 55000;
    for (let i = 24; i >= 0; i--) {
      const timestamp = new Date(now); timestamp.setHours(now.getHours() - i);
      const randomFactor = 0.98 + Math.random() * 0.04; const price = basePrice * randomFactor;
      data.push({ timestamp, price });
    } return data;
  };

  const siteUrl = typeof window !== 'undefined' ? window.location.origin : '';
  // Use resolvedLang which is guaranteed to be valid or default
  const currentActualLanguage = i18n.language.split('-')[0];
  // Calculate basePath based on the actual lang parameter from URL if valid, otherwise assume default
  const langParamLength = lang && isLangValid ? lang.length : defaultLanguage.length;
  const basePath = location.pathname.substring(langParamLength + 1);

  // Conditional rendering/navigation moved inside the return statement
  if (!isLangValid) {
    // Calculate the correct path for redirection based on the invalid lang
    const redirectBasePath = lang ? location.pathname.substring(lang.length + 1) : location.pathname;
    return <Navigate to={`/${defaultLanguage}${redirectBasePath}${location.search}${location.hash}`} replace />;
  }

  return (
    <div className="min-h-screen bg-background-light dark:bg-gray-900 text-gray-900 dark:text-gray-100 transition-colors duration-200">
      <Helmet>
        <html lang={currentActualLanguage} />
        <title>{t('pageTitle')}</title>
        <meta name="description" content={t('pageDescription')} />
        {/* Canonical URL points to the current language-specific URL */}
        <link rel="canonical" href={`${siteUrl}/${currentActualLanguage}${basePath}${location.search}${location.hash}`} />
        {/* Hreflang tags point to corresponding language versions */}
        {supportedLanguages.map(lng => (
          <link key={lng} rel="alternate" hreflang={lng} href={`${siteUrl}/${lng}${basePath}${location.search}${location.hash}`} />
        ))}
        {/* x-default points to the default language version */}
        <link rel="alternate" hreflang="x-default" href={`${siteUrl}/${defaultLanguage}${basePath}${location.search}${location.hash}`} />
      </Helmet>

      <div className="container mx-auto px-4 py-8 flex flex-col min-h-screen">
        <div className="flex-grow">
          <Header currentPrice={currentPrice} lang={currentActualLanguage} location={location} />

          {loading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary dark:border-primary-light"></div>
            </div>
          ) : error ? (
            <div className="bg-red-100 border border-red-400 text-red-700 dark:bg-red-900 dark:border-red-700 dark:text-red-200 px-4 py-3 rounded mt-6">
              {error}
            </div>
          ) : (
            <>
              <div className="mt-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 transition-colors duration-200">
                <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">{t('marketInfoTitle')}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                  <div className="bg-primary-light bg-opacity-10 dark:bg-opacity-20 p-4 rounded-lg">
                    <p className="text-sm text-gray-600 dark:text-gray-400">{t('currentPriceLabel')}</p>
                    <p className="text-2xl font-bold text-primary-dark dark:text-primary-light">
                      ${currentPrice ? currentPrice.toLocaleString('en-US', { maximumFractionDigits: 2 }) : 'N/A'}
                    </p>
                  </div>
                  <div className="bg-primary-light bg-opacity-10 dark:bg-opacity-20 p-4 rounded-lg">
                    <p className="text-sm text-gray-600 dark:text-gray-400">{t('variation24hLabel')}</p>
                    {bitcoinData.length > 0 && currentPrice !== null && (
                      <p className={`text-2xl font-bold ${(currentPrice - bitcoinData[0].price) >= 0 ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        {((currentPrice - bitcoinData[0].price) / bitcoinData[0].price * 100).toFixed(2)}%
                      </p>
                    )}
                  </div>
                  <div className="bg-primary-light bg-opacity-10 dark:bg-opacity-20 p-4 rounded-lg">
                    <p className="text-sm text-gray-600 dark:text-gray-400">{t('high24hLabel')}</p>
                    {bitcoinData.length > 0 && (
                      <p className="text-2xl font-bold text-primary-dark dark:text-primary-light">
                        ${Math.max(...bitcoinData.map(d => d.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                      </p>
                    )}
                  </div>
                  <div className="bg-primary-light bg-opacity-10 dark:bg-opacity-20 p-4 rounded-lg">
                    <p className="text-sm text-gray-600 dark:text-gray-400">{t('low24hLabel')}</p>
                    {bitcoinData.length > 0 && (
                      <p className="text-2xl font-bold text-primary-dark dark:text-primary-light">
                        ${Math.min(...bitcoinData.map(d => d.price)).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-8">
                <div className="lg:col-span-2 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 transition-colors duration-200">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">{t('chartTitle')}</h2>
                  <BitcoinChart data={bitcoinData} />
                </div>
                <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg p-6 transition-colors duration-200">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100 mb-4">{t('gaugeTitle')}</h2>
                  <FearGreedGauge value={fearGreedIndex} />
                </div>
              </div>
              <DonationBox />
              <BitcoinNews />
            </>
          )}
        </div>
        <footer className="mt-12 text-center text-gray-600 dark:text-gray-400 text-sm pb-4">
          <p>{t('footerCopyright', { year: new Date().getFullYear() })}</p>
          <p className="mt-1">{t('footerDataSource')}</p>
        </footer>
      </div>
    </div>
  );
}

// Main App component sets up the routes
function App() {
  const defaultLanguage = 'en'; // Define default language again or import from config

  return (
    <Routes>
      {/* Route to handle language parameter */}
      <Route path="/:lang/*" element={<AppContent />} />

      {/* Redirect from root to default language */}
      <Route path="/" element={<Navigate to={`/${defaultLanguage}`} replace />} />

      {/* Optional: Catch-all for potentially invalid root paths if needed */}
      {/* <Route path="*" element={<Navigate to={`/${defaultLanguage}`} replace />} /> */}
    </Routes>
  );
}

export default App;